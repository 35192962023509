import React from 'react'
import Layout from '../components/Layout/Layout'
import PartnerHeader from '../components/PartnerHeader/PartnerHeader'
import TilesWithList from '../components/TilesWithlList/TilesWithList'
import Contact from '../components/Contact/Contact'
import FAQ from '../components/FAQ/FAQ'
import { graphql } from 'gatsby'
import PdfDownloadSection from '../components/PdfDownload/PdfDownloadSection'

const FranchisePage = ({ data }) => {
  const {
    description,
    strapiCompanyInfo,
    strapiFranchisePage: { header, tilesWithList, faq, contact }
  } = data

  return <Layout description={description}>
    <PartnerHeader {...header} />
    <PdfDownloadSection
      buttonTxt='Herunterladen'
      header='Partner-Franchise-Broschüre'
      description='PDF-Datei mit allen Informationen'
    />
    <TilesWithList {...tilesWithList} />
    <Contact variant='teal' {...strapiCompanyInfo} {...contact} />
    <FAQ {...faq} />
  </Layout>
}

export default FranchisePage

export const query = graphql`
 query FranchisePageQuery{
    strapiFranchisePage {
      description
      ...franchiseHeaderFragment
      ...franchiseTilesWithListFragment
      ...franchiseFAQFragment
      ...franchiseContactFragment
    }
    ...companyInfoFragment
   }
 `
